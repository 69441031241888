import { useEffect, useState, useCallback } from 'react'
import api from 'services/api'
import Loader from 'components/core/Loader'

import Box from '@mui/material/Box'
const DialogueLoader = ({ shareCode }) => {
  const [error, setError] = useState(null)
  const [videoUrl, setVideoUrl] = useState(null)
  const [canPlay, setCanPlay] = useState(false)

  useEffect(() => {
    if (!shareCode) return setError('Share code not provided.')

    api.dialogueVideos
      .getDialogueVideo(shareCode)
      .then(setVideoUrl)
      .catch((e) => setError(e.message))
  }, [shareCode])

  const onCanPlay = useCallback(() => {
    setCanPlay(true)
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& video': {
          height: 750,
          visibility: canPlay ? 'visible' : 'hidden',
        },
      }}
    >
      {!videoUrl && <Loader text="creating video" error={error} />}
      {videoUrl && (
        <video controls autoPlay onCanPlay={onCanPlay}>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </Box>
  )
}

export default DialogueLoader
