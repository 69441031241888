import axios from 'axios'
import { ADMIN_API_URL } from 'const'

const BASE_URL = `${ADMIN_API_URL}/dialogue_videos`

export const getDialogueVideo = async (shareCode) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/${shareCode}`)
    return data.video_url
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
