import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import useModalsActions from 'store/actions/modals'
import { useModal } from 'store/selectors'
import DialoguePlayer from 'components/core/DialoguePlayer'

const DialoguePlayerModal = () => {
  const {
    isOpen,
    params: { shareCode },
  } = useModal('dialoguePlayer')
  const { closeModal } = useModalsActions()

  return (
    <Dialog open={isOpen} onClose={closeModal.bind(null, 'dialoguePlayer')}>
      <Box
        sx={{
          backgroundColor: 'background.default',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {shareCode && (
          <Box
            sx={{
              height: '750px',
              width: '420px',
            }}
          >
            <DialoguePlayer shareCode={shareCode} />
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

export default DialoguePlayerModal
