import ConfirmPublish from './ConfirmPublish'
import PickEditor from './PickEditor'
import DialoguePlayer from './DialoguePlayer'

const Modals = () => {
  return (
    <>
      <ConfirmPublish />
      <PickEditor />
      <DialoguePlayer />
    </>
  )
}

export default Modals
